html,
body,
#app,
#app > div {
  height: 100%;
  width: 100%;
  margin: 0;
  background: white;
}

@font-face {
  font-family: InconsolataRegular;
  src: local('InconsolataRegular'),
    url('./assets/fonts/inconsolata.regular.ttf') format('opentype');
}

@font-face {
  font-family: InconsolataSemiBold;
  src: local('InconsolataSemiBold'),
    url('./assets/fonts/Inconsolata.semibold.ttf') format('opentype');
}

#root {
  height: 100%;
  width: 100%;
  margin: auto;
  text-align: center;
  font-family: 'InconsolataRegular';
  background: white;
}

.react-datepicker .react-datepicker__current-month {
  font-family: 'InconsolataRegular', Sans-serif;
}
