.react-date-picker {
    margin-left: 15px;
    margin-right: 15px;
}
.react-date-picker__wrapper {
    border: none
}
.react-date-picker__calendar .react-calendar {
    box-shadow: rgb(0 0 0 / 15%) 0px 10px 10px;
    border-radius: 4px;
    border: none;
}
.react-calendar__tile--active {
    border-radius: 100px;
}

.react-date-picker__button svg {
    stroke: rgb(42,42,42,0.75)
}

.react-date-picker__inputGroup__input {
    color: rgb(42,42,42,0.75)
}

.react-calendar__tile--active {
    background: #263B53;
    color: white
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: #48719f;
}

.react-calendar__month-view__days__day--weekend {
    color: #F1AD3D;
}

.react-calendar__tile--now {
    background: white;
}

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
    background: white;
}
